import { Box, Button, Menu, MenuItem } from '@mui/material';
import React, { useRef, useState } from 'react';

export interface PopoverMenuItem {
  label: string;
  value: string;
}

interface PopoverMenuProps {
  children: React.ReactNode;
  buttonComponent?: React.ComponentType<{ onClick: () => void }>;
  items: PopoverMenuItem[];
  onClick: (value: string) => void;
}

export default function PopoverMenu(props: PopoverMenuProps) {
  const ButtonComponent = props.buttonComponent ?? Button;
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (value: string) => {
    setIsOpen(false);
    props.onClick(value);
  };

  return (
    <Box>
      <ButtonComponent ref={buttonRef} onClick={() => setIsOpen(true)}>
        {props.children}
      </ButtonComponent>
      <Menu
        anchorEl={buttonRef.current}
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        {props.items.map((item) => (
          <MenuItem
            key={`${item.label}__${item.value}`}
            onClick={() => handleClick(item.value)}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
