import {
  Alert,
  AlertTitle,
  Box,
  capitalize,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { useEffect, useState } from 'react';
import { ErrorResponse } from '@/types/common';
import { GetPoResponse } from '@/types/warehouse';
import api from '@/utils/api';
import { AxiosError } from 'axios';
import Scrollbar from '@/components/Scrollbar';
import { unixToFormattedDate } from '@/utils/formatters';

export interface PoDetailModalProps {
  id: string | null;
  onClose: () => void;
}

export default function PoDetailModal({ id, onClose }: PoDetailModalProps) {
  const theme = useTheme();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [po, setPo] = useState<GetPoResponse['purchaseOrder'] | null>(null);

  const fetchPo = async (id: string) => {
    setLoading(true);
    setError(null);
    setPo(null);

    try {
      const { data } = await api.get<GetPoResponse | ErrorResponse>(
        `/warehouse/fbc/po/${id}`,
      );

      if ('errors' in data) {
        throw new Error(data.errors[0]?.message ?? '');
      }

      setPo(data.purchaseOrder);
    } catch (e) {
      setError(
        e instanceof AxiosError
          ? e.response?.data?.message
          : e instanceof Error
            ? e.message
            : `${e}`,
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id !== null) {
      fetchPo(id);
    }
  }, [id]);

  return (
    <Modal open={id !== null} onClose={onClose}>
      <Container
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
        disableGutters
        maxWidth="lg"
      >
        <Card>
          <CardHeader
            id="po-detail-modal-title"
            title={id}
            action={
              <IconButton onClick={onClose}>
                <CloseTwoToneIcon fontSize="small" />
              </IconButton>
            }
            sx={{ padding: theme.spacing(2, 3) }}
          />
          <Divider />
          <CardContent>
            {loading ? (
              <Box sx={{ textAlign: 'center' }}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                {error !== null && (
                  <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    Failed to load PO: {error || 'something went wrong'}. Please
                    try again.
                  </Alert>
                )}
                {po !== null && (
                  <Scrollbar autoHeight autoHeightMax={'75vh'}>
                    <Grid container>
                      <Grid item xs={12} md={6} paddingRight={theme.spacing(3)}>
                        <TableContainer>
                          <Table size="small">
                            <TableBody>
                              <TableRow>
                                <TableCell variant="head">ID</TableCell>
                                <TableCell>{po.id}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell variant="head">PO Number</TableCell>
                                <TableCell>{po.poNumber}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell variant="head">Warehouse</TableCell>
                                <TableCell>{po.warehouse}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell variant="head">Status</TableCell>
                                <TableCell>{capitalize(po.status)}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell variant="head">
                                  Expected Date
                                </TableCell>
                                <TableCell>
                                  {unixToFormattedDate(po.expectedAt, true)}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell variant="head">
                                  Date Created
                                </TableCell>
                                <TableCell>
                                  {unixToFormattedDate(po.createdAt)}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                      <Grid item xs={12} md={6} sx={{ mt: { xs: 2, sm: 0 } }}>
                        <TableContainer>
                          <Typography
                            variant="h5"
                            paddingBottom={theme.spacing(1)}
                          >
                            PO Items
                          </Typography>
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell>SKU</TableCell>
                                <TableCell>SKU Name</TableCell>
                                <TableCell>Barcode</TableCell>
                                <TableCell>Quantity</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {po.items.map((item) => (
                                <TableRow key={item.wmsSkuId}>
                                  <TableCell>{item.sku}</TableCell>
                                  <TableCell>{item.skuName}</TableCell>
                                  <TableCell>{item.barcode}</TableCell>
                                  <TableCell>{item.quantity}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </Scrollbar>
                )}
              </>
            )}
          </CardContent>
        </Card>
      </Container>
    </Modal>
  );
}
