import FilteredList from '@/components/FilteredList';
import { FormConfig } from '@/components/FilteredList/FilterInput';
import { ErrorResponse } from '@/types/common';
import {
  ListFbcSkusRequest,
  PaginatedSkuResponse,
  Sku,
} from '@/types/warehouse';
import {
  TableColumnConfig,
  Pagination,
  Sort,
} from '@/components/FilteredList/ItemTable';
import { Link, Typography } from '@mui/material';
import { Dayjs } from 'dayjs';
import { DateRange } from '@mui/x-date-pickers-pro';
import api from '@/utils/api';
import { unixToFormattedDate } from '@/utils/formatters';
import { removeEmptyValues } from '@/utils/utils';

interface SkuFilter {
  id: string;
  name: string;
  category: string;
  createdRange: DateRange<Dayjs>;
  updatedRange: DateRange<Dayjs>;
}

interface SkuTableProps {
  onSkuSelected?: (id: string) => void;
}

export default function SkuTable({ onSkuSelected }: SkuTableProps) {
  const filterForm: FormConfig = [
    [
      {
        type: 'text',
        key: 'id',
        label: 'SKU',
      },
      {
        type: 'text',
        key: 'name',
        label: 'Name',
      },
      {
        type: 'text',
        key: 'category',
        label: 'Category',
      },
    ],
    [
      {
        type: 'dateRange',
        key: 'createdRange',
        labelStartDate: 'Created From',
        labelEndDate: 'Created To',
      },
      {
        type: 'dateRange',
        key: 'updatedRange',
        labelStartDate: 'Updated From',
        labelEndDate: 'Updated To',
      },
    ],
  ];
  const columns: TableColumnConfig<Sku>[] = [
    {
      id: 'id',
      label: 'SKU',
      render: (sku) => (
        <Typography noWrap>
          {onSkuSelected ? (
            <Link
              component="button"
              fontWeight="bold"
              onClick={() => onSkuSelected(sku.id)}
            >
              {sku.id}
            </Link>
          ) : (
            sku.id
          )}
        </Typography>
      ),
      sortable: true,
    },
    {
      id: 'imageUrl',
      label: 'Image',
      render: (sku) =>
        sku.imageUrl ? (
          <img src={sku.imageUrl} height={32} alt={`${sku.name}`} />
        ) : (
          '-'
        ),
      sortable: true,
    },
    {
      id: 'name',
      label: 'Name',
      render: (sku) => (
        <Typography noWrap maxWidth={200}>
          {sku.name}
        </Typography>
      ),
      sortable: true,
    },
    {
      id: 'barcode',
      label: 'Barcode',
      render: (sku) => sku.barcode,
      sortable: false,
    },
    {
      id: 'category',
      label: 'Category',
      render: (sku) => sku.category,
      sortable: true,
    },
    {
      id: 'createdAt',
      label: 'Created',
      render: (sku) => unixToFormattedDate(sku.createdAt),
      sortable: true,
    },
    {
      id: 'updatedAt',
      label: 'Updated',
      render: (sku) => unixToFormattedDate(sku.updatedAt),
      sortable: true,
    },
  ];
  const rowsPerPageOptions = [10, 25, 50, 100];
  const loadItems = async (
    filter: SkuFilter,
    pagination: Pagination,
    sort: Sort,
  ) => {
    const requestParams = {
      id: filter.id ?? null,
      name: filter.name ?? null,
      category: filter.category ?? null,
      createdFrom: filter.createdRange?.[0]?.unix() ?? null,
      createdTo:
        filter.createdRange?.[1]?.add(1, 'day').subtract(1, 'second').unix() ??
        null,
      updatedFrom: filter.updatedRange?.[0]?.unix() ?? null,
      updatedTo:
        filter.updatedRange?.[1]?.add(1, 'day').subtract(1, 'second').unix() ??
        null,
      page: pagination.page,
      limit: pagination.limit,
      sortBy: sort.key,
      sortDirection: sort.direction,
    } as ListFbcSkusRequest;

    const response = await api.get<PaginatedSkuResponse | ErrorResponse>(
      '/warehouse/fbc/skus',
      {
        params: removeEmptyValues(requestParams),
      },
    );

    if ('errors' in response.data) {
      throw new Error(response.data.errors[0]?.message ?? '');
    }

    return {
      items: response.data.skus,
      pagination: {
        page: pagination.page,
        limit: pagination.limit,
        count: response.data.total,
      },
      sort: {
        key: response.data.sort.key,
        direction: response.data.sort.direction,
      },
    } as {
      items: Sku[];
      pagination: Pagination;
      sort: Sort;
    };
  };

  return (
    <FilteredList
      filterForm={filterForm}
      columns={columns}
      rowsPerPageOptions={rowsPerPageOptions}
      defaultSortKey="updatedAt"
      loadItems={loadItems}
      getItemId={(sku) => sku.id}
    />
  );
}
