import { useCallback, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  Stack,
  styled,
  TextField,
  useTheme,
} from '@mui/material';
import { Offer } from '@/types/unity';
import { RhfFileSelector } from '../../components/FileUpload/FileSelector';
import { FileWithPath } from 'react-dropzone';

const OfferFormContainer = styled(Box)(
  ({ theme }) => `
    padding: ${theme.spacing(2)} 0;
    border-bottom: solid 1px ${theme.palette.divider};

    &:first-of-type {
      padding-top: 0;
    }
    &:last-of-type {
      border-bottom: none;
      padding-bottom: 0;
    }
`,
);

interface FormValues {
  [offerId: string]: {
    fbcSku: string;
    isDangerousGoods: 'true' | 'false' | null;
    dangerousGoodsFile?: FileWithPath;
  };
}

export interface OfferEnableFbcModalProps {
  offers: Offer[];
  onClose: () => void;
}

export default function OfferEnableFbcModal({
  offers,
  onClose,
}: OfferEnableFbcModalProps) {
  const theme = useTheme();

  const values: FormValues = {};
  for (const offer of offers) {
    values[`${offer.id}`] = {
      fbcSku: offer.fbcSku,
      isDangerousGoods: null,
    };
  }

  const [error, setError] = useState<string | null>(null);
  const { control, handleSubmit, getValues } = useForm<FormValues>({
    values,
  });

  useWatch({
    control,
    name: Object.keys(values).map((offerId) => `${offerId}.isDangerousGoods`),
    disabled: offers.length <= 0,
  });

  const onSubmit = useCallback((formData: FormValues) => {
    console.log('formData', formData);
    // TODO Do something

    // const handleEnableFbc = async (offers: Offer[]) => {
    //   console.log('Enabling FBC for offers', offers);
    //   const response = await api.get<PaginatedOfferResponse | ErrorResponse>(
    //     '/unity/mirakl/offers/enable-fbc',
    //   );

    //   if ('errors' in response.data) {
    //     throw new Error(response.data.errors[0]?.message ?? '');
    //   }

    //   console.log('response:', response.data);
    // };
  }, []);

  return (
    <Modal open={offers.length > 0} onClose={onClose}>
      <Container
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
        disableGutters
        maxWidth="md"
      >
        <Card>
          <CardHeader
            id="offer-enable-fbc-modal-title"
            title={`Enable ${offers.length > 1 ? 'Offers' : 'Offer'} for FBC`}
            action={
              <IconButton onClick={onClose}>
                <CloseTwoToneIcon fontSize="small" />
              </IconButton>
            }
            sx={{ padding: theme.spacing(2, 3) }}
          />
          <Divider />
          <CardContent sx={{ paddingX: 3 }}>
            <>
              {error !== null && (
                <Alert severity="error">
                  <AlertTitle>Error</AlertTitle>
                  Failed to enable FBC on offers:{' '}
                  {error || 'something went wrong'}. Please try again.
                </Alert>
              )}
              <form onSubmit={handleSubmit(onSubmit)}>
                {offers.map((offer) => (
                  <OfferFormContainer key={offer.id}>
                    <Stack
                      sx={{ flexDirection: 'row', columnGap: theme.spacing(4) }}
                    >
                      <Box sx={{ fontWeight: 'bold' }}>
                        {offer.skuName} ({offer.externalOfferSku})
                      </Box>
                      <Box>Price: {offer.price}</Box>
                      <Box>Quantity: {offer.quantity}</Box>
                    </Stack>
                    <Stack
                      sx={{
                        flexDirection: 'row',
                        columnGap: 4,
                        pt: 2,
                      }}
                    >
                      <Controller
                        name={`${offer.id}.fbcSku`}
                        control={control}
                        render={({ field, fieldState }) => (
                          <Box>
                            <TextField
                              label="FBC SKU ID"
                              {...field}
                              error={fieldState.invalid}
                            />
                            {fieldState.invalid && (
                              <FormHelperText error sx={{ my: 1 }}>
                                {fieldState.error?.message || (
                                  <span>
                                    Max length: 19 characters
                                    <br />
                                    Allowed characters: A-Z, 0-9, -, _
                                  </span>
                                )}
                              </FormHelperText>
                            )}
                          </Box>
                        )}
                        rules={{
                          required: 'This field is required',
                          pattern: /^[A-Za-z0-9_-]{1,19}$/,
                        }}
                      />
                      <Controller
                        name={`${offer.id}.isDangerousGoods`}
                        control={control}
                        render={({ field, fieldState }) => (
                          <FormControl>
                            <FormLabel
                              id={`offer_${offer.id}_isDangerousGoods`}
                              error={fieldState.invalid}
                            >
                              Is dangerous goods?
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby={`offer_${offer.id}_isDangerousGoods`}
                              {...field}
                            >
                              <FormControlLabel
                                value="true"
                                control={<Radio />}
                                label="Yes"
                              />
                              <FormControlLabel
                                value="false"
                                control={<Radio />}
                                label="No"
                              />
                            </RadioGroup>
                            {fieldState.error?.message && (
                              <FormHelperText error sx={{ m: 0 }}>
                                {fieldState.error?.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        )}
                        rules={{
                          required: 'This field is required',
                        }}
                      />
                    </Stack>
                    {getValues(`${offer.id}.isDangerousGoods`) === 'true' && (
                      <Box>
                        <RhfFileSelector
                          name={`${offer.id}.dangerousGoodsFile`}
                          control={control}
                          accept={{ 'text/csv': ['.csv'] }}
                          rules={{
                            required: 'This field is required',
                          }}
                        />
                      </Box>
                    )}
                  </OfferFormContainer>
                ))}
                <Button type="submit" variant="contained" sx={{ mt: 2 }}>
                  Submit
                </Button>
              </form>
            </>
          </CardContent>
        </Card>
      </Container>
    </Modal>
  );
}
