import { useState } from 'react';
import PageTitleWrapper from '@/components/PageTitleWrapper';
import { Container, Grid, Typography } from '@mui/material';
import Footer from '@/components/Footer';
import { Offer } from '@/types/unity';
import OfferTable from './OfferTable';
import OfferEnableFbcModal from './OfferEnableFbcModal';

export default function OfferPage() {
  const [selectedOffers, setSelectedOffers] = useState<Offer[]>([]);

  return (
    <>
      <title>Mirakl Offers</title>
      <PageTitleWrapper>
        <Typography variant="h3" component="h3" gutterBottom>
          Mirakl Offers
        </Typography>
        <Typography variant="subtitle2">
          A list of your Mirakl offers.
        </Typography>
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <OfferTable onOffersSelectedForFbc={setSelectedOffers} />
          </Grid>
        </Grid>
      </Container>
      <Footer />

      <OfferEnableFbcModal
        offers={selectedOffers}
        onClose={() => setSelectedOffers([])}
      />
    </>
  );
}
