import FilteredList from '@/components/FilteredList';
import { FormConfig } from '@/components/FilteredList/FilterInput';
import { ErrorResponse } from '@/types/common';
import {
  Fulfilment,
  FulfilmentStatus,
  ListFulfilmentsRequest,
  PaginatedFulfilmentResponse,
  Warehouses,
} from '@/types/warehouse';
import {
  TableColumnConfig,
  Pagination,
  Sort,
} from '@/components/FilteredList/ItemTable';
import { Link, Typography, capitalize } from '@mui/material';
import { Dayjs } from 'dayjs';
import { DateRange } from '@mui/x-date-pickers-pro';
import api from '@/utils/api';
import { unixToFormattedDate } from '@/utils/formatters';
import { removeEmptyValues } from '@/utils/utils';

interface OrderFilter {
  orderId: string;
  warehouse: string;
  status: string;
  consignmentId: string;
  createdRange: DateRange<Dayjs>;
}

export interface OrderTableProps {
  onOrderSelected: (id: string) => void;
}

export default function OrderTable({ onOrderSelected }: OrderTableProps) {
  const filterForm: FormConfig = [
    [
      {
        type: 'text',
        key: 'orderId',
        label: 'Order ID',
      },
      {
        type: 'select',
        key: 'warehouse',
        label: 'Warehouse',
        options: Object.values(Warehouses).map((option) => ({
          label: option,
          value: option,
        })),
      },
      {
        type: 'select',
        key: 'status',
        label: 'Status',
        options: Object.values(FulfilmentStatus).map((option) => ({
          label: capitalize(option),
          value: option,
        })),
      },
    ],
    [
      {
        type: 'text',
        key: 'consignmentId',
        label: 'Tracking ID',
      },
      {
        type: 'dateRange',
        key: 'createdRange',
        labelStartDate: 'Created From',
        labelEndDate: 'Created To',
      },
    ],
  ];
  const columns: TableColumnConfig<Fulfilment>[] = [
    {
      id: 'id',
      label: 'ID',
      render: (fulfilment) => (
        <Typography noWrap>
          {onOrderSelected ? (
            <Link
              component="button"
              fontWeight="bold"
              onClick={() => onOrderSelected(fulfilment.id)}
            >
              {fulfilment.id}
            </Link>
          ) : (
            fulfilment.id
          )}
        </Typography>
      ),
      sortable: true,
    },
    {
      id: 'warehouse',
      label: 'Warehouse',
      render: (fulfilment) => fulfilment.warehouse,
      sortable: true,
    },
    {
      id: 'status',
      label: 'Status',
      render: (fulfilment) => capitalize(fulfilment.status),
      sortable: true,
    },
    {
      id: 'consignmentIds',
      label: 'Tracking IDs',
      render: (fulfilment) => (
        <>
          {fulfilment.consignments.length > 0
            ? fulfilment.consignments.map((consignment) => (
                <Typography key={`${fulfilment.orderId}-${consignment.id}`}>
                  {consignment.id}
                </Typography>
              ))
            : '-'}
        </>
      ),
      sortable: false,
    },
    {
      id: 'createdAt',
      label: 'Created',
      render: (fulfilment) => unixToFormattedDate(fulfilment.createdAt),
      sortable: true,
    },
  ];
  const rowsPerPageOptions = [10, 25, 50, 100];
  const loadItems = async (
    filter: OrderFilter,
    pagination: Pagination,
    sort: Sort,
  ) => {
    const requestParams = {
      orderId: filter.orderId ?? null,
      warehouse: filter.warehouse ?? null,
      status: filter.status ?? null,
      consignmentId: filter.consignmentId ?? null,
      createdFrom: filter.createdRange?.[0]?.unix() ?? null,
      createdTo:
        filter.createdRange?.[1]?.add(1, 'day').subtract(1, 'second').unix() ??
        null,
      page: pagination.page,
      limit: pagination.limit,
      sortBy: sort.key,
      sortDirection: sort.direction,
    } as ListFulfilmentsRequest;

    const response = await api.get<PaginatedFulfilmentResponse | ErrorResponse>(
      '/warehouse/fbc/fulfilment',
      {
        params: removeEmptyValues(requestParams),
      },
    );

    if ('errors' in response.data) {
      throw new Error(response.data.errors[0]?.message ?? '');
    }

    return {
      items: response.data.fulfilments,
      pagination: {
        page: pagination.page,
        limit: pagination.limit,
        count: response.data.total,
      },
      sort: {
        key: response.data.sort.key,
        direction: response.data.sort.direction,
      },
    } as {
      items: Fulfilment[];
      pagination: Pagination;
      sort: Sort;
    };
  };

  return (
    <FilteredList
      filterForm={filterForm}
      columns={columns}
      rowsPerPageOptions={rowsPerPageOptions}
      defaultSortKey="createdAt"
      loadItems={loadItems}
      getItemId={(fulfilment) =>
        `${fulfilment.account.seller.code}_${fulfilment.id}`
      }
    />
  );
}
