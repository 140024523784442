import {
  Alert,
  AlertTitle,
  Box,
  capitalize,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  Link,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { useEffect, useState } from 'react';
import { ErrorResponse } from '@/types/common';
import {
  FulfilmentDetail,
  FulfilmentDetailArticle,
  FulfilmentDetailArticleItem,
  FulfilmentDetailConsignment,
  GetFulfilmentResponse,
} from '@/types/warehouse';
import api from '@/utils/api';
import { AxiosError } from 'axios';
import Scrollbar from '@/components/Scrollbar';
import { unixToFormattedDate } from '@/utils/formatters';

const moneyFormatter = Intl.NumberFormat('en-AU', {
  style: 'currency',
  currency: 'AUD',
});

export interface OrderDetailModalProps {
  id: string | null;
  onClose: () => void;
}

export default function OrderDetailModal({
  id,
  onClose,
}: OrderDetailModalProps) {
  const theme = useTheme();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [order, setOrder] = useState<FulfilmentDetail | null>(null);

  const fetchOrder = async (id: string) => {
    setLoading(true);
    setError(null);
    setOrder(null);

    try {
      const { data } = await api.get<GetFulfilmentResponse | ErrorResponse>(
        `/warehouse/fbc/fulfilment/${id}`,
      );

      if ('errors' in data) {
        throw new Error(data.errors[0]?.message ?? '');
      }

      setOrder(data.fulfilment);
    } catch (e) {
      setError(
        e instanceof AxiosError
          ? e.response?.data?.message
          : e instanceof Error
            ? e.message
            : `${e}`,
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id !== null) {
      fetchOrder(id);
    }
  }, [id]);

  const lineItemDetails = order?.requestedLines
    ? order.requestedLines.map((line) => {
        // Find shipment details
        let consignment: FulfilmentDetailConsignment | null = null;
        let article: FulfilmentDetailArticle | null = null;
        let articleItem: FulfilmentDetailArticleItem | null = null;

        mainLoop: for (const con of order.consignments) {
          for (const art of con.articles) {
            for (const artItem of art.items) {
              if (artItem.sku === line.sku) {
                consignment = con;
                article = art;
                articleItem = artItem;
                break mainLoop;
              }
            }
          }
        }

        return {
          ...line,
          quantityShipped: articleItem?.shippedQuantity || 0,
          dateShipped: consignment?.createdAt || null,
          trackingNumber: consignment?.trackingId || '',
          trackingUrl: consignment?.trackingUrl || '',
          articleId: article?.id || '',
        };
      })
    : [];

  return (
    <Modal open={id !== null} onClose={onClose}>
      <Container
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
        disableGutters
        maxWidth="lg"
      >
        <Card>
          <CardHeader
            id="order-detail-modal-title"
            title={id}
            action={
              <IconButton onClick={onClose}>
                <CloseTwoToneIcon fontSize="small" />
              </IconButton>
            }
            sx={{ padding: theme.spacing(2, 3) }}
          />
          <Divider />
          <CardContent>
            {loading ? (
              <Box sx={{ textAlign: 'center' }}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                {error !== null && (
                  <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    Failed to load order: {error || 'something went wrong'}.
                    Please try again.
                  </Alert>
                )}
                {order !== null && (
                  <Scrollbar autoHeight autoHeightMax={'75vh'}>
                    <Grid container>
                      <Grid item xs={12} md={6} paddingRight={theme.spacing(3)}>
                        <TableContainer>
                          <Typography
                            variant="h5"
                            paddingBottom={theme.spacing(1)}
                          >
                            Details
                          </Typography>
                          <Table size="small">
                            <TableBody>
                              <TableRow>
                                <TableCell variant="head">Order ID</TableCell>
                                <TableCell>{order.orderId}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell variant="head">Warehouse</TableCell>
                                <TableCell>{order.warehouse}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell variant="head">Status</TableCell>
                                <TableCell>
                                  {capitalize(order.status)}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell variant="head">
                                  Date Created
                                </TableCell>
                                <TableCell>
                                  {unixToFormattedDate(order.createdAt)}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell variant="head">
                                  Date Updated
                                </TableCell>
                                <TableCell>
                                  {unixToFormattedDate(order.updatedAt)}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                      <Grid item xs={12} md={6} sx={{ mt: { xs: 2, sm: 0 } }}>
                        <TableContainer>
                          <Typography
                            variant="h5"
                            paddingBottom={theme.spacing(1)}
                          >
                            Delivery
                          </Typography>
                          <Table size="small">
                            <TableBody>
                              <TableRow>
                                <TableCell variant="head">Carrier</TableCell>
                                <TableCell>{order.carrier}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell variant="head">Service</TableCell>
                                <TableCell>{order.service}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell variant="head">Safe Drop</TableCell>
                                <TableCell>
                                  {order.safeDrop ? 'Yes' : 'No'}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell variant="head">
                                  Authority to Leave
                                </TableCell>
                                <TableCell>
                                  {order.authorityToLeave ? 'Yes' : 'No'}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell variant="head">Address</TableCell>
                                <TableCell>
                                  {[
                                    order.delivery.address.businessName,
                                    order.delivery.address.streetAddress1,
                                    order.delivery.address.streetAddress2,
                                    order.delivery.address.locality,
                                    order.delivery.address.region,
                                    order.delivery.address.postalCode,
                                    order.delivery.address.country,
                                  ]
                                    .filter((s) => !!s)
                                    .join(', ')}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell variant="head">Name</TableCell>
                                <TableCell>{order.delivery.name}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell variant="head">Email</TableCell>
                                <TableCell>{order.delivery.email}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell variant="head">Phone</TableCell>
                                <TableCell>{order.delivery.phone}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell variant="head">
                                  Instructions
                                </TableCell>
                                <TableCell>
                                  {order.delivery.instruction || '-'}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                      <Grid item xs={12} marginTop={theme.spacing(2)}>
                        <TableContainer>
                          <Typography
                            variant="h5"
                            paddingBottom={theme.spacing(1)}
                          >
                            Line Items
                          </Typography>
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell>SKU</TableCell>
                                <TableCell>SKU Name</TableCell>
                                <TableCell>Barcode</TableCell>
                                <TableCell>Line Value</TableCell>
                                <TableCell>Quantity</TableCell>
                                <TableCell>Quantity Shipped</TableCell>
                                <TableCell>Date Shipped</TableCell>
                                <TableCell>Tracking No</TableCell>
                                <TableCell>Article ID</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {lineItemDetails.map((line) => (
                                <TableRow key={line.orderLineNumber}>
                                  <TableCell>{line.sku}</TableCell>
                                  <TableCell>{line.name}</TableCell>
                                  <TableCell>{line.barcode}</TableCell>
                                  <TableCell>
                                    {line.lineValue
                                      ? moneyFormatter.format(line.lineValue)
                                      : '-'}
                                  </TableCell>
                                  <TableCell>{line.quantity}</TableCell>
                                  <TableCell>
                                    {line.quantityShipped || 0}
                                  </TableCell>
                                  <TableCell>
                                    {line.dateShipped
                                      ? unixToFormattedDate(line.dateShipped)
                                      : '-'}
                                  </TableCell>
                                  <TableCell>
                                    {line.trackingUrl ? (
                                      <Link
                                        href={line.trackingUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {line.trackingNumber || '-'}
                                      </Link>
                                    ) : (
                                      line.trackingNumber || '-'
                                    )}
                                  </TableCell>
                                  <TableCell>{line.articleId || '-'}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </Scrollbar>
                )}
              </>
            )}
          </CardContent>
        </Card>
      </Container>
    </Modal>
  );
}
