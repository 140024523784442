import { useCallback, useState } from 'react';
import { Accept, FileWithPath } from 'react-dropzone';
import UploadFileSharpIcon from '@mui/icons-material/UploadFileSharp';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormHelperText,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import Dropzone from './Dropzone';
import { Controller, ControllerProps } from 'react-hook-form';

interface FileSelectorProps {
  disabled?: boolean;
  onChange: (file: FileWithPath | null) => void;
  accept?: Accept;
  error?: boolean;
}

export function FileSelector({
  disabled,
  onChange,
  accept,
  error,
}: FileSelectorProps) {
  const theme = useTheme();
  const [file, setFile] = useState<FileWithPath | null>(null);

  const handleDrop = useCallback(
    (acceptedFiles: FileWithPath[]) => {
      const selectedFile = acceptedFiles.length > 0 ? acceptedFiles[0] : null;
      setFile(selectedFile);
      onChange(selectedFile);
    },
    [onChange],
  );

  return (
    <>
      {!file ? (
        <Dropzone onDrop={handleDrop} accept={accept} error={error} />
      ) : (
        <Card sx={{ maxWidth: '100%' }}>
          <CardContent>
            <Stack direction="row" alignItems="center">
              <UploadFileSharpIcon sx={{ marginRight: theme.spacing(1) }} />
              <div>
                <Typography variant="h5" component="div">
                  {file.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {file.path}
                </Typography>
              </div>
            </Stack>
          </CardContent>
          <CardActions>
            <Button
              size="small"
              color="error"
              disabled={disabled}
              onClick={() => handleDrop([])}
            >
              Remove
            </Button>
          </CardActions>
        </Card>
      )}
    </>
  );
}

interface RhfFileSelectorProps extends Omit<ControllerProps, 'render'> {
  accept?: Accept;
}

/**
 * FileSelector component with React Hook Form support
 */
export function RhfFileSelector({ accept, ...props }: RhfFileSelectorProps) {
  return (
    <Controller
      {...props}
      render={({ field, fieldState }) => (
        <>
          <FileSelector
            disabled={field.disabled}
            onChange={field.onChange}
            accept={accept}
            error={fieldState.invalid}
          />
          {fieldState.invalid && (
            <FormHelperText error>
              {fieldState.error?.message || 'Some error'}
            </FormHelperText>
          )}
        </>
      )}
    />
  );
}
