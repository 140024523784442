import FilteredList from '@/components/FilteredList';
import { FormConfig } from '@/components/FilteredList/FilterInput';
import { ErrorResponse } from '@/types/common';
import {
  TableColumnConfig,
  Pagination,
  Sort,
} from '@/components/FilteredList/ItemTable';
import { ListOfferRequest, Offer, PaginatedOfferResponse } from '@/types/unity';
import api from '@/utils/api';
import { unixToFormattedDate } from '@/utils/formatters';
import { removeEmptyValues } from '@/utils/utils';
import { Chip, Typography } from '@mui/material';

interface OfferFilter {
  name: string;
  externalOfferSku: string;
  fbcSku: string;
  fbcEnabled: string;
}

export interface OfferTableProps {
  onOffersSelectedForFbc: (offers: Offer[]) => void;
}

export default function OfferTable({
  onOffersSelectedForFbc,
}: OfferTableProps) {
  const filterForm: FormConfig = [
    [
      {
        type: 'text',
        key: 'name',
        label: 'Name',
      },
      {
        type: 'text',
        key: 'externalOfferSku',
        label: 'Mirakl Offer SKU',
      },
      {
        type: 'text',
        key: 'fbcSku',
        label: 'FBC SKU',
      },
      {
        type: 'select',
        key: 'fbcEnabled',
        label: 'FBC Enabled',
        options: [
          { label: 'Yes', value: 'true' },
          { label: 'No', value: 'false' },
        ],
      },
    ],
  ];

  const columns: TableColumnConfig<Offer>[] = [
    {
      id: 'skuName',
      label: 'Name',
      render: (offer) => (
        <Typography noWrap maxWidth={200}>
          {offer.skuName}
        </Typography>
      ),
      sortable: true,
    },
    {
      id: 'miraklOfferSku',
      label: 'Mirakl Offer SKU',
      render: (offer) =>
        offer.externalId ? (
          <a
            href={`https://marketplace.catch.com.au/mmp/shop/offer/${offer.externalId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {offer.externalOfferSku}
          </a>
        ) : (
          offer.externalOfferSku
        ),
      sortable: false,
    },
    {
      id: 'skuId',
      label: 'SKU',
      render: (offer) => offer.skuId,
      sortable: false,
    },
    {
      id: 'fbcSku',
      label: 'FBC SKU',
      render: (offer) => offer.fbcSku,
      sortable: false,
    },
    {
      id: 'price',
      label: 'Price',
      render: (offer) => offer.price,
      sortable: true,
    },
    {
      id: 'quantity',
      label: 'Quantity',
      render: (offer) => (
        <>
          {offer.quantity}
          {offer.fulfilmentNetwork === 'CATCH' && (
            <Chip
              sx={{ marginLeft: 1 }}
              label="FBC"
              color="primary"
              variant="outlined"
              size="small"
            />
          )}
        </>
      ),
      sortable: true,
    },
    {
      id: 'createdAt',
      label: 'Created',
      render: (offer) => unixToFormattedDate(offer.createdAt),
      sortable: true,
    },
  ];
  const rowsPerPageOptions = [10, 25, 50, 100];
  const loadItems = async (
    filter: OfferFilter,
    pagination: Pagination,
    sort: Sort,
  ) => {
    const requestParams = {
      name: filter.name ?? null,
      externalOfferSku: filter.externalOfferSku ?? null,
      fbcSku: filter.fbcSku ?? null,
      fbcEnabled: filter.fbcEnabled ?? null,
      page: pagination.page,
      limit: pagination.limit,
      sortBy: sort.key,
      sortDirection: sort.direction,
    } as ListOfferRequest;

    const response = await api.get<PaginatedOfferResponse | ErrorResponse>(
      '/unity/mirakl/offers',
      {
        params: removeEmptyValues(requestParams),
      },
    );

    if ('errors' in response.data) {
      throw new Error(response.data.errors[0]?.message ?? '');
    }

    return {
      items: response.data.offers,
      pagination: {
        page: pagination.page,
        limit: pagination.limit,
        count: response.data.total,
      },
      sort: {
        key: response.data.sort.key,
        direction: response.data.sort.direction,
      },
    } as {
      items: Offer[];
      pagination: Pagination;
      sort: Sort;
    };
  };

  const handleEnableFbc = async (offers: Offer[]) => {
    console.log('Enabling FBC for offers', offers);
    const response = await api.get<PaginatedOfferResponse | ErrorResponse>(
      '/unity/mirakl/offers/enable-fbc',
    );

    if ('errors' in response.data) {
      throw new Error(response.data.errors[0]?.message ?? '');
    }

    console.log('response:', response.data);
  };

  return (
    <FilteredList
      filterForm={filterForm}
      columns={columns}
      rowsPerPageOptions={rowsPerPageOptions}
      defaultSortKey="createdAt"
      loadItems={loadItems}
      getItemId={(offer) => `${offer.id}`}
      rowActions={[
        {
          label: 'Enable FBC',
          action: onOffersSelectedForFbc,
          multiplicity: 'both',
        },
      ]}
    />
  );
}
