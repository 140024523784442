import { FormConfig } from '@/components/FilteredList/FilterInput';
import { ErrorResponse } from '@/types/common';
import {
  FbcSkuInventoryReplenishment,
  ListFbcSkuInventoryReplenishmentRequest,
  PaginatedFbcSkuInventoryReplenishmentResponse,
} from '@/types/warehouse';
import {
  Pagination,
  Sort,
  TableColumnConfig,
} from '@/components/FilteredList/ItemTable';
import { Tooltip, Typography } from '@mui/material';
import { removeEmptyValues } from '@/utils/utils';
import api from '@/utils/api';
import FilteredList from '@/components/FilteredList';

interface SkuInventoryReplenishmentFilter {
  skuId: string;
  skuName: string;
}

export default function InventoryReplenishmentTable() {
  const filterForm: FormConfig = [
    [
      {
        type: 'text',
        key: 'skuId',
        label: 'SKU ID',
      },
      {
        type: 'text',
        key: 'skuName',
        label: 'SKU Name',
      },
    ],
  ];
  const columns: TableColumnConfig<FbcSkuInventoryReplenishment>[] = [
    {
      id: 'id',
      label: 'SKU ID',
      render: (skuInventoryReplenishment) => (
        <Typography noWrap>{skuInventoryReplenishment.id}</Typography>
      ),
      sortable: true,
    },
    {
      id: 'name',
      label: 'SKU Name',
      render: (skuInventoryReplenishment) => (
        <Typography noWrap maxWidth={200}>
          {skuInventoryReplenishment.name}
        </Typography>
      ),
      sortable: true,
    },
    {
      id: 'currentInventoryAvailableToSell',
      label: (
        <Tooltip
          title="Quantity available to sell. This is the quantity in our warehouse minus the quantity already allocated to orders."
          placement="top"
        >
          <span>Quantity Unallocated</span>
        </Tooltip>
      ),
      render: (skuInventoryReplenishment) =>
        skuInventoryReplenishment.currentInventoryAvailableToSell,
      sortable: true,
    },
    {
      id: 'currentInventoryOnPo',
      label: (
        <Tooltip
          title="Quantity on Purchase Orders you have created to date."
          placement="top"
        >
          <span>Currently on PO</span>
        </Tooltip>
      ),
      render: (skuInventoryReplenishment) =>
        skuInventoryReplenishment.currentInventoryOnPo,
      sortable: true,
    },
    {
      id: 'weeklySellThroughRate',
      label: (
        <Tooltip
          title="Weekly sell through of this SKU based on the last 30 days of sales."
          placement="top"
        >
          <span>Weekly Sell-Through</span>
        </Tooltip>
      ),
      render: (skuInventoryReplenishment) =>
        skuInventoryReplenishment.weeklySellThroughRate,
      sortable: true,
    },
    {
      id: 'weeksOfCover',
      label: (
        <Tooltip
          title="Number of weeks that the current unallocated quantity will last given the weekly sell-through rate."
          placement="top"
        >
          <span>Weeks of Cover</span>
        </Tooltip>
      ),
      render: (skuInventoryReplenishment) =>
        skuInventoryReplenishment.weeksOfCover,
      sortable: true,
    },
    {
      id: 'recommendedReplen',
      label: (
        <Tooltip
          title="Quantity of inventory to cover 12 weeks of sales. Please consider shipping times and what you already have on PO."
          placement="top"
        >
          <span>Inventory for 12 Weeks of Cover</span>
        </Tooltip>
      ),
      render: (skuInventoryReplenishment) =>
        skuInventoryReplenishment.recommendedReplen,
      sortable: true,
    },
    {
      id: 'soldLast7Days',
      label: (
        <Tooltip title="Quantity sold in the last 7 days." placement="top">
          <span>Sold 7 Days</span>
        </Tooltip>
      ),
      render: (skuInventoryReplenishment) =>
        skuInventoryReplenishment.soldLast7Days,
      sortable: true,
    },
    {
      id: 'soldLast30Days',
      label: (
        <Tooltip title="Quantity sold in the last 30 days." placement="top">
          <span>Sold 30 Days</span>
        </Tooltip>
      ),
      render: (skuInventoryReplenishment) =>
        skuInventoryReplenishment.soldLast30Days,
      sortable: true,
    },
    {
      id: 'soldLast90Days',
      label: (
        <Tooltip title="Quantity sold in the last 90 days." placement="top">
          <span>Sold 90 Days</span>
        </Tooltip>
      ),
      render: (skuInventoryReplenishment) =>
        skuInventoryReplenishment.soldLast90Days,
      sortable: true,
    },
  ];
  const rowsPerPageOptions = [10, 25, 50, 100];
  const loadItems = async (
    filter: SkuInventoryReplenishmentFilter,
    pagination: Pagination,
    sort: Sort,
  ) => {
    const requestParams = {
      skuId: filter.skuId ?? null,
      skuName: filter.skuName ?? null,
      page: pagination.page,
      limit: pagination.limit,
      sortBy: sort.key,
      sortDirection: sort.direction,
    } as ListFbcSkuInventoryReplenishmentRequest;

    const response = await api.get<
      PaginatedFbcSkuInventoryReplenishmentResponse | ErrorResponse
    >('/warehouse/fbc/skus/inventory/replenishment', {
      params: removeEmptyValues(requestParams),
    });

    if ('errors' in response.data) {
      throw new Error(response.data.errors[0]?.message ?? '');
    }

    return {
      items: response.data.soh,
      pagination: {
        page: pagination.page,
        limit: pagination.limit,
        count: response.data.total,
      },
      sort: {
        key: response.data.sort.key,
        direction: response.data.sort.direction,
      },
    } as {
      items: FbcSkuInventoryReplenishment[];
      pagination: Pagination;
      sort: Sort;
    };
  };

  return (
    <FilteredList
      filterForm={filterForm}
      columns={columns}
      rowsPerPageOptions={rowsPerPageOptions}
      defaultSortKey="id"
      loadItems={loadItems}
      getItemId={(skuInventoryReplenishment) => skuInventoryReplenishment.id}
    />
  );
}
