import {
  ArrowDropDownTwoTone,
  MoreHorizTwoTone,
  RefreshTwoTone,
} from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Box,
  Checkbox,
  CircularProgress,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { ReactNode, useState } from 'react';
import PopoverMenu from '../PopoverMenu';

export interface TableColumnConfig<T> {
  id: string;
  label: string | ReactNode;
  sortable: boolean;
  render: (item: T) => ReactNode;
}

export interface Sort {
  key: string;
  direction: 'ASC' | 'DESC';
}

export interface Pagination {
  page: number;
  limit: number;
  count: number;
}

export interface RowActionConfig<T> {
  label: string;
  action: (rows: T[]) => void;
  multiplicity: 'single' | 'multiple' | 'both';
}

export interface ItemTableProps<T> {
  isLoading: boolean;
  error: string | null;
  emptyMessage: string;
  maxHeight?: number | string;
  stickyHeader?: boolean;
  items: T[];
  getItemId: (item: T) => string;
  columns: TableColumnConfig<T>[];
  sort: Sort;
  onSortChange: (newSort: Sort) => void;
  pagination: Pagination;
  onPaginationChange: (newPagination: Pagination) => void;
  rowsPerPageOptions: number[];
  onReload?: () => void;
  rowActions?: RowActionConfig<T>[];
}

export default function ItemTable<T>({
  isLoading,
  error,
  emptyMessage,
  maxHeight,
  stickyHeader,
  items,
  getItemId,
  columns,
  sort,
  onSortChange,
  pagination,
  onPaginationChange,
  rowsPerPageOptions,
  onReload,
  rowActions,
}: ItemTableProps<T>) {
  const multipleRowActions =
    rowActions?.filter(
      (actionConfig) =>
        actionConfig.multiplicity === 'multiple' ||
        actionConfig.multiplicity === 'both',
    ) ?? [];
  const singleRowActions =
    rowActions?.filter(
      (actionConfig) =>
        actionConfig.multiplicity === 'single' ||
        actionConfig.multiplicity === 'both',
    ) ?? [];

  const handleRowActionClick = (label: string, entries: T[]) => {
    const actionConfig = rowActions?.find((config) => config.label === label);
    if (!actionConfig) {
      return;
    }

    actionConfig.action(entries);
  };

  const handleSortChange = (key: string) => () => {
    const currentlyAscending = sort.key === key && sort.direction === 'ASC';
    onSortChange({
      key,
      direction: currentlyAscending ? 'DESC' : 'ASC',
    });
  };

  const handlePageChange = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    onPaginationChange({
      ...pagination,
      page: newPage,
    });
  };

  const handleRowsPerPageChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (event) => {
    const newLimit = parseInt(event.target.value, 10);
    onPaginationChange({
      ...pagination,
      limit: newLimit,
    });
  };

  const [selectedRows, setSelectedRows] = useState<T[]>([]);
  const onSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedRows(items);
      return;
    }
    setSelectedRows([]);
  };
  const onRowClick = (row: T) => {
    if (selectedRows.includes(row)) {
      setSelectedRows(selectedRows.filter((r) => r !== row));
    } else {
      setSelectedRows([...selectedRows, row]);
    }
  };

  const renderHeaderRow = () => (
    <TableRow>
      {multipleRowActions.length > 0 && (
        <TableCell>
          <Checkbox
            color="primary"
            indeterminate={
              selectedRows.length > 0 && selectedRows.length < items.length
            }
            checked={selectedRows.length === items.length}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all rows',
            }}
          />
        </TableCell>
      )}
      {columns.map((column) => (
        <TableCell key={`th-${column.id}`}>
          {column.sortable ? (
            <TableSortLabel
              active={sort.key === column.id}
              direction={
                sort.key === column.id
                  ? (sort.direction.toLowerCase() as Lowercase<
                      typeof sort.direction
                    >)
                  : 'asc'
              }
              onClick={handleSortChange(column.id)}
            >
              {column.label}
              {sort.key === column.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {`sorted ${
                    sort.direction === 'DESC' ? 'descending' : 'ascending'
                  }`}
                </Box>
              ) : null}
            </TableSortLabel>
          ) : (
            column.label
          )}
        </TableCell>
      ))}
      {singleRowActions.length > 0 && <TableCell />}
    </TableRow>
  );

  const renderItemRows = () =>
    items.map((item) => (
      <TableRow
        hover
        key={getItemId(item)}
        tabIndex={-1}
        selected={selectedRows.includes(item)}
      >
        {multipleRowActions.length > 0 && (
          <TableCell>
            <Checkbox
              color="primary"
              checked={selectedRows.includes(item)}
              onChange={() => onRowClick(item)}
            />
          </TableCell>
        )}
        {columns.map((header) => (
          <TableCell key={`item-${getItemId(item)}-${header.id}`}>
            {header.render(item)}
          </TableCell>
        ))}
        {singleRowActions.length > 0 && (
          <TableCell>
            <PopoverMenu
              buttonComponent={IconButton}
              items={
                singleRowActions.map((action) => ({
                  label: action.label,
                  value: action.label,
                })) ?? []
              }
              onClick={(value) => handleRowActionClick(value, [item])}
            >
              <MoreHorizTwoTone />
            </PopoverMenu>
          </TableCell>
        )}
      </TableRow>
    ));

  const columnsCount =
    columns.length +
    (multipleRowActions.length > 0 ? 1 : 0) +
    (singleRowActions.length > 0 ? 1 : 0);

  return (
    <>
      <TableContainer sx={{ maxHeight }}>
        <Table stickyHeader={stickyHeader}>
          <TableHead>{renderHeaderRow()}</TableHead>
          <TableBody>
            {!isLoading && error !== null ? (
              <TableRow>
                <TableCell colSpan={columnsCount}>
                  <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    Failed to load data: {error || 'something went wrong'}.
                    Please try again.
                  </Alert>
                </TableCell>
              </TableRow>
            ) : isLoading ? (
              <TableRow>
                <TableCell align="center" colSpan={columnsCount}>
                  <CircularProgress sx={{ margin: 2 }} />
                </TableCell>
              </TableRow>
            ) : !items.length ? (
              <TableRow>
                <TableCell align="center" colSpan={columnsCount}>
                  {emptyMessage}
                </TableCell>
              </TableRow>
            ) : (
              renderItemRows()
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack
        p={2}
        direction="row"
        justifyContent={selectedRows.length > 0 ? 'space-between' : 'flex-end'}
        spacing={2}
      >
        {selectedRows.length > 0 && (
          <Box>
            <PopoverMenu
              items={
                multipleRowActions.map((action) => ({
                  label: action.label,
                  value: action.label,
                })) ?? []
              }
              onClick={(value) => handleRowActionClick(value, selectedRows)}
            >
              {selectedRows.length > 0 ? `${selectedRows.length} selected` : ''}
              <ArrowDropDownTwoTone />
            </PopoverMenu>
          </Box>
        )}
        <Box>
          {onReload ? (
            <IconButton onClick={onReload} disabled={isLoading}>
              <RefreshTwoTone />
            </IconButton>
          ) : null}
          <TablePagination
            component="div"
            count={pagination.count}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            page={pagination.page}
            rowsPerPage={pagination.limit}
            rowsPerPageOptions={rowsPerPageOptions}
          />
        </Box>
      </Stack>
    </>
  );
}
